.share-group {
    background-color: transparent;
    padding: 10px 8px 10px 16px;
    color: var(--font-color);
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.5rem;
    font-family: var(--font-family);
    line-height: 21px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.wrapper {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    width: 280px;
    padding-bottom: 8px;
    max-height: 448px;
}

.share-arrow {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 16px;
    position: relative;
    margin: 0px 4px;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

