.controls-custom {
    width: calc(100% - 160px);
    height: 24px;
    padding-inline: 16px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease 0s;
    bottom: 28px;
    cursor: initial;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    max-width: 56.25vh;
    z-index: 1;

    &:hover .seekbar-circle {
        display: block;
    }

    &:hover .progress {
        height: 6px;
    }

    // &:hover .current-seek {
    //     height: 6px;
    // }
}

.seekbar-progress {
    width: 100%;
    height: 24px;
    flex: 1 1 auto;
    position: relative;
    user-select: none;
}

.seekbar-time {
    flex: 0 0 88px;
    max-width: 88px;
    margin-inline-start: 8px;
    font-size: 14px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: end;
    white-space: nowrap;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    font-family: Tahoma, PingFangSC, sans-serif;
}

.progress {
    height: 4px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.34);
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer !important;
}

.current-seek {
    height: 4px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer !important;
    transform-origin: left center;
}

.seekbar-circle {
    display: none;
    width: 16px;
    height: 16px;
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer !important;
    z-index: 1;

    // js
    left: calc(61.2361%);
}

.seekbar-time {
    flex: 0 0 88px;
    max-width: 88px;
    margin-inline-start: 8px;
    font-size: 1.4rem;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: end;
    white-space: nowrap;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
}

.video__content {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;

    video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &:hover .controls-custom {
        opacity: 1;
    }
}

.btn-play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.animation-seek-video {
    position: absolute;
    left: 50%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    z-index: 5;
    width: unset;
    top: unset;
    bottom: 81px;
    transform: translate(-50%, 0px);
    height: 40px;
    padding: 8px 16px;
    padding-inline: 8px 8px;
    display: inline-flex;
    flex-direction: row-reverse;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    animation: 2000ms linear 0s 1 normal none running animation-1kguwp9;

    span {
        color: rgba(255, 255, 255, 0.9);
        font-family: var(--tiktok-font);
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }

    .seek-arrow {
        width: 40px;
        height: 20px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        margin-inline-start: unset;
        margin-inline-end: -8px;

        overflow: hidden;
        margin: 0px auto;
        outline: none;

        svg {
            animation: animation-seek 1s linear infinite;
        }


    }
}

@keyframes animation-seek {
    0% {
        transform: translateX(-20px);
        opacity: 0;
    }

    50% {
        transform: translateX(-10px);
        opacity: 0.8;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes animation-1kguwp9 {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}