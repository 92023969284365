:root {
    --search-button-witdh: 59px;
}

.header {
    justify-content: space-around;
    box-shadow: rgba(255, 255, 255, 0.12) 0px 1px 1px;
    height: 60px;
    width: 100%;
    top: 0px;
    position: fixed;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(18, 18, 18);
    z-index: 1000;
    padding: 0 16px;

    .inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            display: flex;
            align-items: center;
        }



        .action {
            display: flex;
            align-items: center;
        }

    }
}

.more-btn {
    cursor: pointer;
    color: var(--white-color);
    margin-left: 16px;
    font-size: 1.6rem;
    background-color: transparent;
    padding: 0 10px;
}

.action-btn {
    padding: 6px;
    cursor: pointer;
    background-color: transparent;
}

.action-btn-mes {
    width: 26px;
    height: 26px;
}

.action-btn-inbox {
    width: 32px;
    height: 32px;
}

.action-btn+.action-btn {
    margin-left: 10px;
}

.btn-left {
    margin-left: 10px;
}

.user-avatar {
    width: 32px;
    height: 32px;
    margin-left: 12px;
    background: transparent;
    border-radius: 23px;
    cursor: pointer;
    object-fit: cover;
    overflow: hidden;
}