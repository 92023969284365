.wrapper {
    font-size: 1.6rem;
    // padding: 5px 32px;
    height: 32px;
    font-weight: 600;
    font-family: var(--font-family);
    border-radius: 4px;
    background-color: var(--primary-color);
    min-width: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    +.wrapper {
        margin-left: 16px;
    }
}

.icon+.title,
.title+.icon {
    margin-left: 8px;
}

// ??
.title {
    display: flex;
    align-items: center;
}

.icon {
    display: flex;
    width: 24px;
    align-items: center;
    justify-content: flex-start;
}

.primary {
    color: var(--white-color);
    background-color: var(--primary-color);

    &:hover {
        opacity: 0.9;
        transition: opacity 0.2s ease-in-out;
    }

    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), rgb(254, 44, 85);
    }
}

.primaryLonger {
    color: var(--white-color);

    border-radius: 4px;
    border: none;
    background-color: var(--btn-text-color);
    min-height: 36px;
    font-size: 18px;
    font-family: TikTokDisplayFont, PingFangSC, sans-serif;
    font-weight: 500;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 6px 8px;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 208px;
    line-height: 22px;

    &:hover {
        opacity: 0.9;
        transition: opacity 0.2s ease-in-out;
    }

    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), rgb(254, 44, 85);
    }
}

.outline {
    font-family: var(--tiktok-font);
    font-size: 1.6rem;
    font-weight: 500;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: var(--btn-text-color);
    border-color: var(--btn-text-color);
    background-color: rgba(255, 255, 255, 0.08);
    min-width: 106px;
    min-height: 28px;
    line-height: 22px;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 6px 8px;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
        background-color: rgba(254, 44, 85, 0.06);
    }

    &:active {
        background-color: rgba(254, 44, 85, 0.16);
    }
}

.small {
    min-width: 75px;
    height: 26px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: transparent;
    font-weight: 500;

    &:hover {
        opacity: 0.9;
        transition: opacity 0.2s ease-in-out;
    }
}

.large {
    min-height: 45px;
    min-width: 140px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: transparent;

    &:hover {
        opacity: 0.9;
        transition: opacity 0.2s ease-in-out;
    }
}

.text {
    min-width: 110px;
    padding: 0px 16px;
    height: 34px;
    border: 1px solid rgba(22, 24, 35, 0.12);
    border-radius: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.08);
    color: var(--font-color);

    &:hover {
        opacity: 0.9;
        transition: opacity 0.2s ease-in-out;
    }

    // css for the icon
    svg {
        margin-right: 8px;
    }
}

.disabled {
    opacity: 0.5;
    user-select: none;
    cursor: not-allowed;

    &:hover {
        opacity: 0.5;
    }

    svg {
        opacity: 0.5;
    }
}

.rounded {
    border-radius: 23px;
    box-shadow: 0 0 2px 1px #494949;
    background-color: transparent;

    &:hover {
        box-shadow: 0 0 4px 1px #737373;
    }

    &:active {
        box-shadow: 0 0 4px 1px #737373;
    }
}

.nomal {
    border-radius: 999px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    background: rgba(84, 84, 84, 0.5);
    transition: all 0.2s ease;
    color: var(--font-color);
    font-size: 1.4rem;
    font-weight: 600;
    min-width: fit-content;

    &:hover {
        background-color: rgba(37, 37, 37, 0.6);
    }

    &:active {
        opacity: 0.5;
    }
}

.dark {
    box-sizing: border-box;
    appearance: none;
    min-width: 96px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
    font-family: 'TikTokFont', Arial, Tahoma, PingFangSC, sans-serif;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    background-clip: padding-box;
    font-size: 1.6rem;
    height: 36px;
    padding-inline: 15px;
    color: var(--font-color);
    background-color: #252525;
    border-color: #ffffff1f;
    transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;

    &:hover {
        background-color: #2e2e2e;
        border-color: #fff0;
    }

    &:active {
        background-color: #363636;
        border-color: #fff0;
    }
}