.wrapper {
    display: flex;
    padding: 9px 16px;
    cursor: pointer;
    align-items: center;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.account-avatar {
    width: 40px;
    height: 40px;
    margin: 0px 12px 0px 0px;

    .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
}

.account-name {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #FFFFFFE6;
    display: flex;
    align-items: center;
}

.account-iconCheck {
    margin-left: 6px;
    border-radius: 23px;
    font-size: 1.3rem;
}

.account-username {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}