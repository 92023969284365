.content {
    width: 230px;
}

.menu-items {
    background-color: transparent;
    padding: 10px 8px 10px 16px;
    color: var(--font-color);
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.5rem;
    font-family: var(--font-family);
    line-height: 21px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    &.separate {
        border-top: 1px solid rgba(255, 255, 255, 0.12);
    }
}

.menu-scroll {
    overflow-y: auto;
}

.header {
    position: relative;
    height: 50px;
    cursor: pointer;
    margin-top: -8px;
    flex-shrink: 0;
}

.back-btn {
    width: 50px;
    height: 100%;
    background-color: transparent;
    color: var(--primary-text-color);
    cursor: pointer;
}

.header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
}