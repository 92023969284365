.wrapper {
    position: relative;
    margin: 0px auto;
    width: 100%;
    min-width: 420px;
    flex-shrink: 1;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.container {
    width: 736px;
    padding-top: 20px;
}

.user-card {
    width: 226px;
    height: 302px;
    border-radius: 8px;
    margin-bottom: 18px;
    display: block;
    float: left;
    overflow: hidden;
    margin-inline-end: 18px;

    a {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        background-color: rgba(255, 255, 255, 0.12);
    }
}

.video {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-color: rgba(255, 255, 255, 0.08);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.info {
    position: absolute;
    background: transparent;
    bottom: 0px;
    width: 100%;
    height: 200px;
    display: flex;
    box-sizing: border-box;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 12px 20px;
    text-align: center;

    h3 {
        color: rgb(255, 255, 255);
        font-family: var(--tiktok-font);
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 24px;
        width: 100%;
        margin: 0px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h4 {
        max-width: 100%;
        margin: 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        overflow: hidden;

        span {
            color: rgb(255, 255, 255);
            font-family: var(--tiktok-font);
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 18px;
            max-width: 180px;
            margin: 0px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        svg {
            margin-left: 4px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-shrink: 0;
        }
    }
}

.avatar {
    margin-bottom: 14px;
    flex-shrink: 0;
    width: 48px;
    height: 48px;

    display: inline-block;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-feature-settings: "tnum";
    position: relative;
    overflow: hidden;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    line-height: 32px;
    border-radius: 50%;
    background-color: rgba(136, 136, 136, 0.5);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.btn-follow {
    margin-top: 8px;

    button {
        border-radius: 4px;
        border: none;
        color: rgb(255, 255, 255);
        min-width: 164px;
        min-height: 36px;
        font-size: 1.8rem;
        line-height: 25px;
        font-weight: 500;
        font-family: TikTokDisplayFont, PingFangSC, sans-serif;
        display: flex;
        position: relative;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 6px 8px;
        user-select: none;
        cursor: pointer;
        box-sizing: border-box;
    }
}