.wrapper {
    display: flex;
    align-items: center;
}

.avatar {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 23px;
    background-color: #242424;
    background-image: linear-gradient(90deg, rgba(22, 24, 35, 0) 0%, rgba(49, 49, 52, 1) 50%, rgba(22, 24, 35, 0) 100%);
    -webkit-background-size: 200% 100%;
    background-size: 200% 100%;
    background-repeat: no-repeat;
    -webkit-animation: loading cubic-bezier(0, 0, 1, 1) 1.5s infinite;
    animation: loading cubic-bezier(0, 0, 1, 1) 1.5s infinite;
}

.account-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // margin-left: 12px;
    height: 48px;
}

.animation {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 4px;
    background-image: linear-gradient(90deg, rgba(22, 24, 35, 0) 0%, rgba(49, 49, 52, 1) 50%, rgba(22, 24, 35, 0) 100%);
    background-size: 200% 100%;
    background-repeat: no-repeat;
    height: 16px;
    width: 40%;
    animation: 1.5s cubic-bezier(0, 0, 1, 1) 0s infinite normal none running loading;
}

.nickname {
    width: 107px;
    height: 12px;
}

.name {
    width: 66px;
    height: 12px;
}

@keyframes loading {

    0% {
        background-position-x: 200%;
    }

    100% {
        background-position-x: -150%;
    }
}