@import 'normalize.css';

:root {
    --primary-color: #FF3B5C;
    --btn-text-color: rgb(255, 59, 92);
    --white-color: #fff;
    --font-family: 'ProximaNova', san-serif;
    --backgroud-color: #121212;
    --font-color: rgba(255, 255, 255, 0.9);
    --tiktok-font: TikTokFont, Arial, Tahoma, PingFangSC, sans-serif;
    --back-color-img-dark: #fff;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
    background-color: var(--backgroud-color);
    overflow-y: overlay;
}

body {
    font-family: var(--font-family);
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
    color: #fff;
    overflow-y: overlay;
}

// scroll bar
html ::-webkit-scrollbar {
    border-radius: 0px;
    width: 8px;
}

html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.08);
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, .08);
}

html ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

a {
    text-decoration: none;
    color: inherit;
}

li {
    list-style: none;
}

button {
    outline: none;
    border: none;
    user-select: none;
}

// Fonts emmbeded
@font-face {
    font-family: ProximaNova;
    src: url('/assets/fonts/Proxima-Nova-Regular.woff2');
    font-weight: 400;
}

@font-face {
    font-family: ProximaNova;
    src: url('/assets/fonts/ProximaNova-Semibold.woff2');
    font-weight: 600;
}

@font-face {
    font-family: ProximaNova;
    src: url('/assets/fonts/Proxima-Nova-Bold.woff');
    font-weight: 700;
}

@font-face {
    font-family: SofiaPro;
    src: url('/assets/fonts/sofiapro-bold.woff');
    font-weight: 700;
}

// @font-face {
//     font-family: TikTokFont;
//     src: url('/assets/fonts/TikTokFont-Bold.woff2');
//     font-weight: 700;
// }

// @font-face {
//     font-family: TikTokFont;
//     src: url('/assets/fonts/TikTokFont-Regular.woff2');
//     font-weight: 400;
// }

// @font-face {
//     font-family: TikTokFont;
//     src: url('/assets/fonts/TikTokFont-Semibold.woff2');
//     font-weight: 500;
// }

// @font-face {
//     font-family: TikTokDisplayFont;
//     src: url('/assets/fonts/TikTokDisplayFont-Bold.woff2');
//     font-weight: bold;
// }

// @font-face {
//     font-family: TikTokDisplayFont;
//     src: url('/assets/fonts/TikTokDisplayFont-Semibold.woff2');
//     font-weight: 500;
// }

// @font-face {
//     font-family: TikTokDisplayFont;
//     src: url('/assets/fonts/TikTokDisplayFont-Regular.woff2');
//     font-weight: normal;
// }

// test
@font-face {
    font-family: 'TikTokFont';
    src: url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Regular.woff2?_default_font=1&v=2) format('woff2'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Regular.woff?_default_font=1&v=2) format('woff'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Regular.otf?_default_font=1&v=2) format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'TikTokFont';
    src: url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Semibold.woff2?_default_font=1&v=2) format('woff2'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Semibold.woff?_default_font=1&v=2) format('woff'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Semibold.otf?_default_font=1&v=2) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'TikTokFont';
    src: url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Semibold.woff2?_default_font=1&v=2) format('woff2'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Semibold.woff?_default_font=1&v=2) format('woff'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Semibold.otf?_default_font=1&v=2) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'TikTokFont';
    src: url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Bold.woff2?_default_font=1&v=2) format('woff2'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Bold.woff?_default_font=1&v=2) format('woff'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokFont-Bold.otf?_default_font=1&v=2) format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'TikTokDisplayFont';
    src: url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Regular.woff2?_default_font=1&v=2) format('woff2'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Regular.woff?_default_font=1&v=2) format('woff'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Regular.otf?_default_font=1&v=2) format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'TikTokDisplayFont';
    src: url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Semibold.woff2?_default_font=1&v=2) format('woff2'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Semibold.woff?_default_font=1&v=2) format('woff'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Semibold.otf?_default_font=1&v=2) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'TikTokDisplayFont';
    src: url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Semibold.woff2?_default_font=1&v=2) format('woff2'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Semibold.woff?_default_font=1&v=2) format('woff'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Semibold.otf?_default_font=1&v=2) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'TikTokDisplayFont';
    src: url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Bold.woff2?_default_font=1&v=2) format('woff2'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Bold.woff?_default_font=1&v=2) format('woff'),
        url(https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok_fonts/TikTokDisplayFont-Bold.otf?_default_font=1&v=2) format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

// end

// custom Tippy
.tippy-box {
    text-align: left;
    text-decoration: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 2.1rem;
    background-color: rgba(84, 84, 84, 0.92);
    color: rgb(255, 255, 255);
    padding: 3px;
}

.tippy-arrow {
    color: rgba(84, 84, 84, 0.92);
}

.go-to-top {
    border: none;
    outline: none;
    padding: 0px;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 52px;
    background: var(--btn-text-color);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 8px;

    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), rgb(254, 44, 85);
    }

    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), rgb(254, 44, 85);
    }
}

.bottom-container.visible {
    opacity: 1;
    transform: translateY(0);
    background-color: green;
}

.bottom-container {
    right: 24px;
    position: fixed;
    bottom: 0px;
    z-index: 7;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 8px;
    transform: translateY(40px);
    transition: all 400ms cubic-bezier(0.65, 0, 0.35, 1) 0s;
}

.tran {
    transform: none;
}

.get-app {
    margin-bottom: 12px;
    display: flex;
    position: relative;

    .btn-get-app {
        border: 1px solid rgba(255, 255, 255, 0);
        background: none rgb(37, 37, 37);
        outline: none;
        color: rgba(255, 255, 255, 0.75);
        font-family: TikTokFont, Arial, Tahoma, PingFangSC, sans-serif;
        font-weight: 700;
        font-size: 14px;
        // position: relative;
        right: 0px;
        bottom: 0px;
        padding: 0px 8px;
        min-width: 90px;
        height: 32px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 8px;
        border-radius: 52px;
        cursor: pointer;
        line-height: 30px;
        text-align: center;
        white-space: nowrap;
        transition: transform 0.3s cubic-bezier(0.4, 0.14, 0.3, 1) 0s;
        transform-origin: right bottom;
        // transform: none;

        &:hover {
            border-color: rgba(255, 255, 255, 0);
            background-color: rgb(46, 46, 46);
        }

        &:active {
            border-color: rgba(255, 255, 255, 0);
            background-color: rgba(255, 255, 255, 0.12);
        }
    }
}

@keyframes animation-1u0u36m {
    25% {
        cx: 100px;
        r: 43.2px;
    }

    50% {
        cx: 140px;
        r: 36px;
    }

    75% {
        cx: 100px;
        r: 21.6px;
    }

    100% {
        cx: 60px;
        r: 36px;
    }
}

@keyframes animation-1gjz153 {
    25% {
        cx: 100px;
        r: 24px;
    }

    50% {
        cx: 60px;
        r: 36px;
    }

    75% {
        cx: 100px;
        r: 43.2px;
    }

    100% {
        cx: 140px;
        r: 36px;
    }
}

@keyframes animation-1gjz153 {
    25% {
        cx: 100px;
        r: 24px;
    }

    50% {
        cx: 60px;
        r: 36px;
    }

    75% {
        cx: 100px;
        r: 43.2px;
    }

    100% {
        cx: 140px;
        r: 36px;
    }
}

.btn-more-video {
    background-color: rgb(37, 37, 37);
    left: 0px;

    padding: 8px 0px;
    margin: 0px;
    min-width: 200px;
    list-style: none;
    box-sizing: border-box;
    border-radius: 8px;
    user-select: none;
}