.nav {
    position: relative;
    top: 0px;
    z-index: 0;
    display: flex;
    flex-direction: row;
    -webkit-box-align: stretch;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    height: 44px;
    margin-bottom: 8px;
    background-color: rgb(18, 18, 18);

    &::after {
        position: absolute;
        bottom: 0px;
        left: 0px;
        content: "";
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.12);
        transform: scaleY(0.5);
    }
}

.nav-item {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    height: 44px;
    padding: 0px 32px;
    cursor: pointer;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);

    svg {
        margin-right: 5px;
    }
}

.nav-line{
    position: absolute;
    height: 2px;
    background: var(--font-color);
    bottom: 0px;
    transition: all 0.3s ease 0s;
}

.active {
    color: var(--font-color);
}