.wrapper {
    width: 100%;
    margin-top: 60px;

    .container {
        display: flex;
        height: 100%;
        min-height: 420px;

        .content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            margin-left: 240px;

            .content-inner {
                width: 100%;
                max-width: 1328px;
                display: flex;
                padding: 0 24px 0;
                flex-direction: column;
                flex: 1 1 auto;
                min-height: calc(100vh - 59px);
                margin: 0px auto;
                box-sizing: content-box;
            }
        }
    }

}