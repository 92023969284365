.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.68);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    z-index: 1001;
}

.overlay {
    background-color: #252525;
    width: 483px;
    height: 642px;
    padding: 0px 0px 64px;
    border-radius: 10px;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    position: relative;
    z-index: 999;
    animation: zoomIn 0.3s linear;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes zoomOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.2);
    }
}

.close {
    position: absolute;
    top: 24px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1;
    right: 24px;
    width: 24px;
    height: 24px;
    background: rgba(255, 255, 255, 0.04);
    transform: scale(1.7);

    &:hover {
        background: rgba(255, 255, 255, 0.08);
    }

    &:active {
        background: rgba(255, 255, 255, 0.12);
    }
}

.login-container {
    width: 363px;
    margin: 64px auto auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    color: var(--font-color);
    font-size: 33px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;
}

.login-options {
    overflow-y: scroll;
    padding: 2px 5px 2px 13px;
    height: 362px;
    width: 100%;
}

.agreement-container {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: rgb(18, 18, 18);
    padding: 16px 30px;
    margin-bottom: unset;

    p {
        font-family: TikTokFont;
        color: rgba(255, 255, 255, 0.5);
        font-size: 1.2rem;
        line-height: 15px;
        width: 337px;
        text-align: center;

        & .agreement-text {
            display: inline;
            color: var(--font-color);
        }
    }

}

.signUp-container {
    color: var(--font-color);
    font-family: TikTokFont;
    height: 64px;
    border-top: 0.5px solid rgba(255, 255, 255, 0.12);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    -webkit-box-align: center;
    align-items: center;
    font-size: 15px;
    line-height: 18px;
    width: 100%;

    a {
        margin-left: 5px;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--btn-text-color);
    }
}