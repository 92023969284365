.wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 100%;
    margin: unset;
}

.video-card {
    position: relative;
    box-sizing: border-box;
    background-size: cover;
    cursor: pointer;
    margin-right: 20px;
    height: calc(316.667px + 17.3611vw);
    border-radius: 10px;
    overflow: hidden;

    video {
        object-fit: cover;
    }

    // video:hover .mask-overlay {
    //     background-color: red;
    // }
}

.action-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.action-icon {

    button {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 0px;
        border-radius: 50%;
        transition: all 200ms ease-in-out 0s;
        width: 48px;
        height: 48px;
        background-color: rgba(255, 255, 255, 0.12);
        margin-top: 8px;
        margin-bottom: 6px;
    }
}

.action-count {
    color: rgba(255, 255, 255, 0.75);
    font-family: var(--tiktok-font);
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    font-weight: 700;
}

.video-overlay {
    position: absolute;
    top: 50%;
    opacity: 0;
}

.action-video {
    color: rgb(255, 255, 255);
    font-family: TikTokFont, Arial, Tahoma, PingFangSC, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    right: 16px;
    position: absolute;
    top: 16px;
    transition: opacity 0.3s ease 0s;
    // opacity: 0;
}

.mask-overlay {
    height: 40%;
    background: linear-gradient(rgba(18, 18, 18, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    width: 100%;
    position: absolute;
    bottom: 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    transition: opacity 0.3s ease 0s;
}

.control-bot {
    width: 100%;
    height: 12px;
    position: absolute;
    bottom: 0px;
    cursor: initial;
}

.control-video {
    display: flex;
    align-items: flex-end;
    padding: 0px 12px;
    position: absolute;
    bottom: 26px;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    user-select: none;

    .btn-volume {
        padding: 8px;
        width: 40px;
        height: 40px;
        transition: opacity 0.3s ease 0s;
    }

    .control-volume {
        width: 24px;
        height: 64px;
        margin: 8px 7px 0px;
        position: relative;
        cursor: initial;
        background: rgba(22, 24, 35, 0.34);
        border-radius: 32px;
    }

    .volume-progress {
        width: 2px;
        height: 48px;
        background-color: rgba(255, 255, 255, 0.34);
        position: absolute;
        left: 11px;
        top: 8px;
        bottom: 8px;
        cursor: pointer;
        border-radius: 4px;
    }

    .volume-circle {
        width: 12px;
        height: 12px;
        background-color: rgb(255, 255, 255);
        border-radius: 12px;
        position: absolute;
        left: 6px;
        bottom: 8px;
        cursor: pointer;
        z-index: 1;
    }

    .volume-bar {
        width: 2px;
        height: 48px;
        background-color: rgb(255, 255, 255);
        position: absolute;
        left: 11px;
        bottom: 8px;
        cursor: pointer;
        transform: scaleY(0);
        transform-origin: center bottom;
        border-radius: 4px;

        // js
        // transform: scaleY(1);
    }
}

.progress-video {
    width: 100%;
    height: 16px;
    padding-inline: 12px;
    position: absolute;
    transition: opacity 0.3s ease 0s;
    bottom: 12px;
    cursor: initial;
    display: flex;

    // js
    // opacity: 0;
}

.seekbar-time {
    flex: 0 0 64px;
    max-width: 64px;
    margin-inline-start: 8px;
    font-size: 10px;
    line-height: 16px;
    color: rgb(255, 255, 255);
    text-align: end;
    white-space: nowrap;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    font-family: Tahoma, PingFangSC, sans-serif;
}

.seekbar-control {
    width: 100%;
    height: 16px;
    flex: 1 1 auto;
    position: relative;
    cursor: pointer;
    user-select: none;

    &:hover {
        .seekbar-circle {
            opacity: 1;
        }

        .seekbar-current {
            height: 4px;
        }

        .seekbar-progress {
            height: 4px;
        }
    }
}

.seekbar-progress {
    height: 2px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.34);
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.seekbar-circle {
    width: 12px;
    height: 12px;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 1;
    opacity: 0;
}

.seekbar-current {
    height: 2px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transform-origin: left center;
}