.intro {
    margin-bottom: 20px;
}

.share-info {
    display: flex;
    align-items: center;
    color: #ffffffe6;
    position: relative;
    max-width: 624px;
    padding-right: 92px;
    padding-left: 0px;
    min-height: 140px;
}

.avatar {
    width: 116px;
    height: 116px;
    object-fit: cover;
    border-radius: 9999px;
}

.share-title {
    flex: 1 1 0%;
    cursor: pointer;
    overflow: visible;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-left: 20px;
}

.title {
    font-family: TikTokDisplayFont, Arial, Tahoma, PingFangSC, sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    word-break: break-word;

    svg {
        margin-left: 10px;
    }
}

.sub-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-overflow: ellipsis;
    height: 25px;
    overflow: hidden;
    max-width: 450px;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
}

.btn {
    font-family: var(--tiktok-font);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    width: 208px;
    height: 36px;
    padding: 0px;
    margin: 16px 0px 0px;
}

.action {
    position: absolute;
    top: 11px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 18px;
    right: 1px;
}

.count-info {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 22px;
}

.unit {
    color: rgba(255, 255, 255, 0.75);
    font-family: var(--tiktok-font);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    margin-left: 6px;
}

.count-info-number {
    margin-right: 20px;
    color: var(--font-color);
    font-family: var(--tiktok-font);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    direction: ltr;
    -webkit-box-align: baseline;
    align-items: baseline;
    cursor: pointer;

    .unit:hover {
        text-decoration: underline;
    }
}

.bio-text {
    text-align: left;
    color: var(--font-color);
    font-family: var(--tiktok-font);
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    white-space: pre-line;
    margin-top: 10px;
}

.link-bio {
    font-family: var(--tiktok-font);
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--primary-color);

    svg {
        margin-right: 2px;
    }

    &:hover {
        text-decoration: underline;
    }
}

.error-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    margin: 0px auto;
    min-height: 400px;
}

.error-title {
    font-size: 24px;
    line-height: 30px;
    font-family: TikTokDisplayFont, Arial, Tahoma, PingFangSC, sans-serif;
    font-weight: 700;
    color: var(--font-color);
    margin-top: 24px;
}

.error-des {
    font-size: 16px;
    line-height: 21px;
    font-family: var(--tiktok-font);
    font-weight: 400;
    color: rgba(255, 255, 255, 0.75);
    margin-top: 8px;
}

.bio {
    max-width: 624px;

    a {
        width: fit-content;
    }
}

.btn-edit-profile {
    button {
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        color: rgba(255, 255, 255, 0.9);
        border-color: rgba(255, 255, 255, 0);
        background-color: rgba(255, 255, 255, 0.08);
        min-height: 36px;
        font-weight: 600;
        position: relative;
        -webkit-box-pack: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
        box-sizing: border-box;
        height: 36px;
        font-size: 16px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 0px 16px;
        min-width: 136px;
        line-height: 24px;
        transition: all 0.1s ease-in-out;

        svg {
            min-width: 30px;
            scale: 0.8;
        }

        &:hover {
            border-color: rgb(208, 209, 211);
            background-color: rgb(18, 18, 18);
        }

        &:active {
            border-color: rgb(208, 209, 211);
            background-color: rgba(255, 255, 255, 0.08);
        }
    }
}

.btn-followed {
    display: flex;
    align-items: center;

    button {
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        color: var(--btn-text-color);
        border-color: var(--btn-text-color);
        background-color: rgba(255, 255, 255, 0.08);
        min-width: 164px;
        min-height: 36px;
        font-size: 1.8rem;
        font-weight: 600;
        display: flex;
        position: relative;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 6px 8px;
        user-select: none;
        cursor: pointer;
        box-sizing: border-box;
        height: 36px;
        line-height: 22px;
        margin-right: 8px;
        transition: all 0.1s ease-in-out;

        &:hover {
            background-color: rgba(254, 44, 85, 0.06);
        }

        &:active {
            background-color: rgba(254, 44, 85, 0.16);
        }

    }
}

.btn-followed-sub {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    border: 1px solid rgba(22, 24, 35, 0.12);
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    background-color: rgba(255, 255, 255, 0.08);
    float: right;
    transition: all 0.1s ease-in-out;

    &:hover {
        background-color: rgba(255, 255, 255, 0.04);
    }

    &:active {
        background-color: rgba(255, 255, 255, 0.08);
    }
}