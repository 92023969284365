// search
.search-results {
    width: 527.17px;

    .search-label {
        height: 30px;
        padding: 5px 12px;
        font-size: 1.4rem;
        line-height: 18px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.5);
    }
}

.search {
    height: 70%;
    // width: 37%;
    min-width: 200px;
    width: 516px;
    margin: 0 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 23px;
    overflow: hidden;
    position: relative;
    border: 2px solid transparent;
    font-family: var(--font-family);

    &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 50%;
        background-color: rgba(255, 255, 255, 0.12);
        right: 52px;

    }

    &:focus-within {
        border-color: rgb(255 255 255 / 12%);
    }

    input {
        border: none;
        outline: none;
        background-color: transparent;
        color: var(--font-color);
        font-size: 1.6rem;
        width: 100%;
        padding: 0 16px;
        border-right-color: rgba(255, 255, 255, 0.12);
        caret-color: var(--primary-color);
        padding-right: 40px;
    }

    input:not(:placeholder-shown)~.search-btn {
        color: rgba(255, 255, 255, 0.75);
    }


    .close,
    .loading {
        cursor: pointer;
        font-size: 1.5rem;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.34);
        position: absolute;
        right: calc(var(--search-button-witdh) + 13px);

    }

    .loading {
        animation: spin 0.8s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .search-btn {
        cursor: pointer;
        width: var(--search-button-witdh);
        height: 100%;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.34);
        font-size: 1.8rem;

        &:hover {
            // background-color: #484848f2;
            background-color: rgba(255, 255, 255, 0.12);
            transition: all 0.03s ease-in-out;
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.03);
        }
    }
}