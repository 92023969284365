.wrapper {
    &::after {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 1px;
        transform: scaleY(0.5);
        background: rgba(255, 255, 255, 0.2);
    }
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--font-color);
}

.info-text {
    flex: 1;
    max-width: 528px;

    a {
        width: fit-content;
        display: flex;
        align-items: flex-start;

        h3 {
            font-family: var(--tiktok-font);
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            display: inline-block;
            margin-right: 4px;
        }

        h4 {
            font-family: var(--tiktok-font);
            font-weight: 400;
            font-size: 14px;
            display: inline-block;
            line-height: 28px;
        }
    }
}

.info-des {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--tiktok-font);
    color: var(--font-color);
    font-size: 1.6rem;
}

.info-sound {
    margin-bottom: 12px;
}

.video-music {
    font-family: var(--tiktok-font);
    line-height: 21px;
    margin-top: 2px;
    margin-left: 2px;
    font-weight: 400;
    font-size: 1.4rem;

    a {
        display: flex;
        -webkit-box-align: center;
        align-items: center;

        div {
            -webkit-line-clamp: 1;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }

        svg {
            margin-right: 5px;
        }
    }

    &:hover {
        text-decoration: underline;
    }
}

.info-btn {
    position: absolute;
    right: 0;
    top: 28px;

    button {
        box-sizing: border-box;
        appearance: none;
        min-width: 96px;
        display: inline-flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        border-style: solid;
        border-width: 1px;
        border-radius: 2px;
        text-decoration: none;
        cursor: pointer;
        background-clip: padding-box;
        height: 36px;
        padding-inline: 15px;
    }
}


.anchor-tag {
    display: flex;
    gap: 2px 6px;
    flex-wrap: wrap;
    margin-left: -2px;

    .anchor-container {
        margin-bottom: 0px;
        min-width: 0px;
        height: 22px;
        padding: 1px 6px 1px 4px;
        background: rgba(255, 255, 255, 0.04);
        display: inline-flex;
        border-radius: 4px;
        -webkit-box-align: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.9);
        margin-top: 2px;

        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        text-decoration: none;
        transition: all 0.2s linear;

        img {
            width: 14px;
            height: 14px;
            object-fit: cover;
            border-radius: 3px;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.08);
            text-decoration: underline;
        }
    }

    .anchor-tag-name {
        font-family: var(--tiktok-font);
        font-size: 1.3rem;
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-left: 8px;
    }
}