.wrapper {
    padding: 16px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.account-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        background: rgba(255, 255, 255, 0.04);
    }
}

.avatar {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    object-fit: cover;
    border-radius: 23px;
}

.nickname {
    color: var(--font-color);
    font-family: var(--tiktok-font);
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    max-width: 145px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    svg {
        margin-left: 4px;
    }
}

.name {
    color: rgba(255, 255, 255, 0.75);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    max-width: 145px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.label {
    padding: 0px 8px;
    margin-bottom: 8px;
    font-family: var(--tiktok-font);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.75);
}

.see-more {
    background-color: transparent;
    border: none;
    padding: 0px 8px;
    margin-top: 8px;
    cursor: pointer;
    color: var(--btn-text-color);
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
}

.no-data {
    color: rgba(255, 255, 255, 0.5);
    font-family: var(--tiktok-font);
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 18px;
    padding: 0px 8px;
}