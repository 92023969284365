.wrapper {
    background: rgba(0, 0, 0, 0.68);
    position: fixed;
    inset: 0px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;


    .children {
        width: 100%;
        border-radius: 10px;
        position: relative;
    }
}