.wrapper {
    padding-bottom: 8px;
    width: 180px;
}

.more-action {
    background-color: transparent;
    padding: 10px 8px 10px 16px;
    color: var(--font-color);
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.5rem;
    font-family: var(--font-family);
    line-height: 21px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}