.footer {
    padding-top: 16px;
    padding-left: 8px;
    position: relative;
    margin-bottom: 50px;
}

.footer-banner {
    padding-right: 8px;
    margin-bottom: 30px;
    width: 208px;
    height: 52px;
    background-image: url('https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/tiktok/webapp/main/webapp-desktop/d8db931296c3e5645b1e.png');
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .btn-icon {
        width: 19px;
        height: 19px;
        margin-right: 9px;
        color: var(--font-color);
    }

    &:hover {
        scale: 1.1;
        transition: scale .2s ease-in-out;
    }
}

.copy-right {
    color: rgba(255, 255, 255, 0.5);
    font-family: var(--tiktok-font);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    margin-right: 6px;
    margin-top: 5px;
}

.getApp-expand {
    font-family: var(--tiktok-font);
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    // position: absolute;
    bottom: -1px;
    background: rgb(37, 37, 37);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 8px;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 8px;
    transition: all 0.3s cubic-bezier(0.4, 0.14, 0.3, 1) 0s;
    transform-origin: right bottom;
    // transform: scale(0);
    right: -1px;
}

.getApp-close {
    right: 16px;
    position: absolute;
    top: 16px;
    cursor: pointer;
}

.getApp-expand-wrapper {
    margin-right: 48px;
    margin-left: 8px;
}

.expand-item {
    margin: 6px 0px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    cursor: pointer;
}