.container {
    position: relative;
    border-radius: 8px;
    margin: auto;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    transform: none;
    background-color: rgb(18, 18, 18);
    width: 310px;
}

.title {
    font-family: TikTokDisplayFont, Arial, Tahoma, PingFangSC, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    padding: 28px 24px;
    text-align: center;
}

.btn-options {
    display: flex;
    flex-direction: column;

    button {
        border: none;
        background: none;
        outline: none;
        padding: 0px;
        font-family: TikTokFont, Arial, Tahoma, PingFangSC, sans-serif;
        font-weight: 400;
        font-size: 14px;
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        border-top: 1px solid rgba(47, 47, 47, 0.7);
        transition: all 0.2s ease-in-out 0s;

        &:hover {
            background-color: rgba(47, 47, 47, 0.5);
        }

        &:active {
            background-color: rgba(47, 47, 47, 0.7);
        }
    }
}